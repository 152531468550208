<a
  role="button"
  class="btn"
  [routerLink]="link()"
  [fragment]="scrollTo()"
  [ngClass]="{
    'btn-primary': color() === 'primary',
    'btn-secondary': color() === 'secondary',
  }"
>
  <ng-content />
</a>
