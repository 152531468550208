import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lumiron-list-title',
  templateUrl: 'list-title.component.html',
  styleUrl: './list-title.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class ListTitleComponent {}
